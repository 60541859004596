// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_content_delivery_bucket": "aws-20190301172145-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "eu-central-1",
    "aws_content_delivery_url": "https://d3hw4g9ejc8mqy.cloudfront.net",
    "aws_cognito_identity_pool_id": "eu-central-1:10a97bdc-d1be-4c48-b6d2-3caa30723575",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_2r4CMPTwq",
    "aws_user_pools_web_client_id": "2ktdv1b920buv9qumgm8bhu7q9",
    "aws_mobile_analytics_app_id": "e805e66f289a4818bcd93f1d058443f8",
    "aws_mobile_analytics_app_region": "eu-central-1",
    "aws_user_files_s3_bucket": "awscdb9281ff05d471e8066ed3b62d1de6b-dev",
    "aws_user_files_s3_bucket_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://xyqkcxcqrjhyhefuayq2ej3424.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
